// store.js
//import { reactive } from 'vue' // 2.6 last vue version before change to reactive
import Vue from 'vue'
import axios from 'axios'
import ExcelJS from 'exceljs'

export const store = Vue.observable({
  // [[globals]]
  propsToSearch: {},
  tableColumns: {},
  colWidthDict: false,
  ogPropsToSearch: false,
  ogTableColumns: false,
  userConfirm: false,
  mmData: false,
  reset_pass: false,
  customQuery: false,
  mmLoader: false,
  mmCustomCSVHeaders: {},
  aggregateSelected: 'total',
  companySelected: 'all',
  siteSelected: ['all'],
  productSelected: ['all'],
  subProductSelected: ['all'],
  productSubtypeSelected: '',
  segmentSelected: '',
  demoSelected: '',
  reachSelected: '',
  campaignSelected: '',
  placementSelected: ['all'],
  languageSelected: '',
  dmaSelected: '',
  genderSelected: '',
  ageSelected: '',
  deviceSelected: 'all',
  interSelected: 'all',
  intervalSelected: 15,
  keywordsInput: '',
  csvFile: '',
  exportRows: {},
  mundialData: [],
  mmText: 'Last 30 Days',
  mmDateRange: [],
  mmAvailsRange: '7',
  adminRemoveArr: ['ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete','revenue'],
  mmRemoveArr: ['inter','ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete','revenue'],
  clientRemoveArr: ['billing_entity','vertical','lang','inter','ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete','revenue'],
  mmMinMaxDate: [],
  customMinMaxDate: [],
  //customMinMaxDate: {},
  mmUploadCSV(event) {
    const files = event.target.files,
    fileName = files[0].name,
    fileReader = new FileReader(),
    self = this;

    //console.log(fileName);

    // Add Listener
    fileReader.addEventListener('load', () => {
      const result = fileReader.result.replace(/\"/g, ''),
      csvData = result && result.length ? result.split(/[\r\n,]+/).map(keyword => keyword.trim()).filter(Boolean).join(", ") : '';

      //console.log({csvData})
      //console.log({csvData,length:csvData.length,split:csvData.split(/[\r\n,]+/).map(keyword => keyword.trim()).filter(Boolean),join:csvData.split(/[\r\n,]+/).map(keyword => keyword.trim()).filter(Boolean).join(", ")})

      self.keywordsInput = csvData
      //return csvData && csvData.length ? csvData.split(/[\r\n,]+/).map(keyword => keyword.trim()).filter(Boolean).join(", ") : ''
    })

    // Retrieve CSV
    fileReader.readAsText(files[0])
  },
  summarizeRows(tableRows, dimsArr, metricSet) {
    const summaryDict = {};
    let summaryArr = [],
    dailyRows = JSON.parse(JSON.stringify(tableRows)),
    dailyLen = dailyRows.length;

    for (let r = 0; r < dailyLen; r++) {

      // Create new tupleKey
      const cleanRow = dailyRows[r];
      delete cleanRow['date']

      let tmpArr = [], tupleArr = [];

      // Parse through dims & create tuples
      for (let d = 0; d < dimsArr.length; d++) {

        if (dimsArr[d] == 'date') { continue; }

        const tmpDim = dimsArr[d],
        tmpVal = cleanRow[tmpDim];

        tupleArr.push(tmpVal)
      }
      let tmpTuple = tupleArr.join('___');

      // Compile summary
      if (!summaryDict.hasOwnProperty( tmpTuple )) {
        summaryDict[tmpTuple] = summaryArr.length;
        summaryArr.push(cleanRow)
      } else {
        const tmpIdx = summaryDict[tmpTuple];
        for (const metric of metricSet.values()) {
          summaryArr[tmpIdx][metric] += cleanRow[metric]
        }
      }
    }
    return summaryArr
  },
  sanitizeMetrics(cleanRows, metricSet, summaryBool) {
    const math_round = Math.round,
    ogRows = JSON.parse(JSON.stringify(cleanRows)),
    colWidthDict = {}, colSet = new Set(Object.keys(cleanRows[0])),
    dimSet = new Set([...colSet].filter(x => !metricSet.has(x))),
    cleanLen = cleanRows.length;

    //console.log({cleanRows, dimSet, metricSet, colsDict:this.colsDict})

    if (cleanLen) {
      for (let c = 0; c < cleanLen; c++) {
        const tmpRow = cleanRows[c],
        ogRow = ogRows[c], metricArr = [...metricSet],
        metricLen = metricArr.length;


        //console.log({tmpRow,ogRow})


        for (let m = 0; m < metricLen; m++) {
          const metric = metricArr[m];

          switch (metric) {
            case 'adreq':
              tmpRow.adreq = ogRow.adreq ? ogRow.adreq.toLocaleString("en-US") : 0
              break;
            case 'mon_adreq':
              tmpRow.mon_adreq = ogRow.mon_adreq ? ogRow.mon_adreq.toLocaleString("en-US") : 0
              break;
            case 'imp':
              tmpRow.imp = ogRow.imp ? ogRow.imp.toLocaleString("en-US") : 0
              break;
            case 'revenue':
              tmpRow.revenue = ogRow.revenue ? `${new Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD'}).format(ogRow.revenue)}` : '$0.00'
              //tmpRow.revenue = ogRow.revenue ? `$${ogRow.revenue.toFixed(2)}` : '$0.00'
              break;
            case 'ivt_rate':
              if (summaryBool) {
                tmpRow.ivt_rate = ogRow.ivt_adreq ? `${((100 * ogRow.ivt_adreq) / (ogRow.adreq - ogRow.network_adreq)).toFixed(1)}%` : ''
              } else {
                tmpRow.ivt_rate = ogRow.ivt_rate ? `${ogRow.ivt_rate}%` : ''
              }
              break;
            case 'viewability':
              if (summaryBool) {
                tmpRow.viewability = ogRow.pre_view_imp ? `${(100 * ogRow.view_imp / ogRow.pre_view_imp).toFixed(1)}%` : ''
              } else {
                tmpRow.viewability = ogRow.viewability ? `${ogRow.viewability}%` : ''
              }
              break;
            case 'vcr':
              if (summaryBool) {
                tmpRow.vcr = ogRow.video_start ? `${(100 * ogRow.video_complete / ogRow.video_start).toFixed(1)}%` : ''
              } else {
                tmpRow.vcr = ogRow.vcr ? `${ogRow.vcr}%` : ''
              }
              break;
            case 'fill_rate':
              if (summaryBool) {
                tmpRow.fill_rate = ogRow.fill_rate ? `${(100 * ogRow.imp / ogRow.mon_adreq).toFixed(1)}%` : ''
              } else {
                tmpRow.fill_rate = ogRow.fill_rate ? `${ogRow.fill_rate}%` : ''
              }
              break;
            case 'inter':
              tmpRow.inter = ogRow.inter ? 'Yes' : ''
              break;
            case 'archive_flag':
              tmpRow.archive_flag = ogRow.archive_flag ? 'Yes' : ''
              break;
            case 'ignore_flag':
              tmpRow.ignore_flag = ogRow.ignore_flag ? 'Yes' : ''
              break;
            case 'status_level':
              tmpRow.status_level = ogRow.status_level ? 'Active' : ''
              break;
            case 'last_login':
              tmpRow.last_login = ogRow.last_login ? ogRow.last_login.split('T')[0]  : ''
              break;
            case 'is_active':
              tmpRow.is_active = ogRow.is_active ? 'Active' : ''
              break;
            case 'active':
              tmpRow.active = ogRow.active ? 'Active' : ''
              break;
            case 'be_id':
              tmpRow.be_id = ogRow.be_id ? this.idBeDict[ogRow.be_id] : ''
              break;
            case 'sn_id':
              tmpRow.sn_id = ogRow.sn_id ? this.idSnDict[ogRow.sn_id] : ''
              break;
            case 'device_type_id':
              tmpRow.device_type_id = ogRow.device_type_id ? this.idDeviceDict[ogRow.device_type_id] : ''
              break;
            case 'product_subtype_id':
              tmpRow.product_subtype_id = ogRow.product_subtype_id ? this.idSubProductDict[ogRow.product_subtype_id] : ''
              break;
            case 'vertical_id':
              tmpRow.vertical_id = ogRow.vertical_id ? this.idDemoDict[ogRow.vertical_id] : ''
              break;
            case 'lang_id':
              tmpRow.lang_id = ogRow.lang_id ? this.idLangDict[ogRow.lang_id] : ''
              break;
            case 'country_id':
              tmpRow.country_id = ogRow.country_id ? this.idCountryDict[ogRow.country_id] : ''
              break;
            case 'platform_id':
              tmpRow.platform_id = ogRow.platform_id ? this.idPlatformDict[ogRow.platform_id].toUpperCase() : ''
              break;
            case 'campaign_id':
              tmpRow.campaign_id = ogRow.campaign_id ? this.idCampaignDict[ogRow.campaign_id] : ''
              break;
            case 'placement_id':
              tmpRow.placement_id = ogRow.placement_id ? this.idPlacementDict[ogRow.placement_id] : ''
              break;
            case 'placement_group_id':
              tmpRow.placement_group_id = ogRow.placement_group_id ? this.idPlacementDict[ogRow.placement_group_id] : ''
              break;
            case 'o_and_o':
              tmpRow.o_and_o = ogRow.o_and_o ? 'Yes' : ''
              break;
            case 'am_name_id':
              tmpRow.am_name_id = ogRow.am_name_id ? 'Adrian Ruiz' : ''
              break;
            case 'lead_name_id':
              tmpRow.lead_name_id = ogRow.lead_name_id ? 'Adrian Ruiz' : ''
              break;
            case 'counter':
              tmpRow.counter = ogRow.counter ? ogRow.counter.toLocaleString("en-US") : 0
              break;
            case 'map_bool':
              tmpRow.map_bool = !ogRow.map_bool.length ? false : ogRow.map_bool
              break;
            default:
              continue
          }

          // Populate metric widths
          //console.log({metric, tmpRow})

          const tmpLen = Number.isInteger(tmpRow[metric]) ? tmpRow[metric].toString().length : tmpRow[metric].length,
          titleLen = this.colsDict.hasOwnProperty(metric) && this.colsDict[metric].length > 12 ? this.colsDict[metric].length : 12;

          //console.log({metric, tmpLen, titleLen})

          if (!colWidthDict.hasOwnProperty(metric)) colWidthDict[metric] = 0;
          //if (tmpLen > colWidthDict[metric] || titleLen > colWidthDict[metric]) colWidthDict[metric] = titleLen > tmpLen ? titleLen * 9 + 60 : tmpLen * 9 + 60;
          if (tmpLen > colWidthDict[metric] || titleLen > colWidthDict[metric]) colWidthDict[metric] = titleLen > tmpLen ? titleLen * 10 + 60 : tmpLen * 10 + 80;

        }

        const dimArr = [...dimSet],
        dimLen = dimArr.length;
        for (let d = 0; d < dimLen; d++) {

          // Populate dim widths
          const dim = dimArr[d], tmpLen = Number.isInteger(tmpRow[dim]) ? tmpRow[dim].toString().length : tmpRow[dim].length,
          titleLen = this.colsDict.hasOwnProperty(dim) && this.colsDict[dim].length > 12 ? this.colsDict[dim].length : 12;

          //console.log({dim, tmpLen, titleLen})

          if (!colWidthDict.hasOwnProperty(dim)) colWidthDict[dim] = 0;
          //if (tmpLen > colWidthDict[dim] || titleLen > colWidthDict[dim]) colWidthDict[dim] = titleLen > tmpLen ? titleLen * 8 + 80 : tmpLen * 8 + 80;
          if (tmpLen > colWidthDict[dim] || titleLen > colWidthDict[dim]) colWidthDict[dim] = titleLen > tmpLen ? titleLen * 10 + 60 : tmpLen * 10 + 80;

        }

      }
    }
    this.colWidthDict = colWidthDict

    //console.log({colWidthDict})

    return cleanRows
  },
  //filterMundial([['type', 'wood'], ['size', 8], ['someother', 'value']]);
  filterMundial(filters, data_list) {
    const reformatDate = this.reformatDate, self = this,
    filterLen = filters.length,
    unfilteredList = this.customQuery ? this.mmData.custom : data_list,
    timeBool = unfilteredList.length ? unfilteredList[0]['date'].indexOf('T') !== -1 : false;

    //console.log({filters, data_list, unfilteredList}, timeBool)
    //console.log({mundial:this.mmData.mundial})
    //console.log(this.customQuery ? this.mmData.custom : this.mmData.mundial)

    return unfilteredList.filter(function(val) {


      //console.log({val, filters, length:filters.length})


      let bool = true;
      for(let i = 0; i < filterLen; i++) {


        //console.log({val:val[filters[i][0]],filter:filters[i]})


        if(filters[i][0] == 'date') {
          //const objCheck = filters[i][1][0] instanceof Date,
          //sDate = objCheck ? parseInt(new Date(filters[i][1][0].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][0].replaceAll('-','')),
          //eDate = objCheck ? parseInt(new Date(filters[i][1][1].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][1].replaceAll('-','')),
          //tmpDate = reformatDate(val[filters[i][0]]);

          const objCheck = filters[i][1][0] instanceof Date,
          sDate = objCheck && timeBool ? parseInt(new Date(filters[i][1][0].getTime() + 3600 * 1000 * 24).toISOString().replace(/[:\-T]/g, ''))
          : !objCheck && timeBool ? parseInt(`${filters[i][1][0].replace(/[:\-T]/g, '')}000000`)
          : objCheck ? parseInt(new Date(filters[i][1][0].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][0].replaceAll('-','')),
          eDate = objCheck && timeBool ? parseInt(new Date(filters[i][1][1].getTime() + 3600 * 1000 * 24).toISOString().replace(/[:\-T]/g, ''))
          : !objCheck && timeBool ? parseInt(`${filters[i][1][1].replace(/[:\-T]/g, '')}000000`)
          : objCheck ? parseInt(new Date(filters[i][1][1].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][1].replaceAll('-','')),
          tmpDate = timeBool ? val[filters[i][0]].replace(/[:\-T]/g, '') : reformatDate(val[filters[i][0]]);



          //console.log({sDate,eDate,tmpDate,objCheck})



          if (tmpDate < sDate - 1) {
            bool = false;
          }
          if (tmpDate > eDate - 1) {
            bool = false;
          }
          if (self.customQuery && tmpDate == sDate) {
            bool = true;
          }
          if (self.mmData.sessionFilters && self.customQuery && tmpDate == sDate - 1) {
            bool = true;
          }
          /*
          if (self.mmData.sessionFilters && self.mmData.adjust_custom && tmpDate < eDate - 2) {
            bool = true;
          }
          if (self.customSession && tmpDate > eDate - 1) {
            bool = false;
          }
          */
          if (objCheck || self.customQuery) {
            self.mmText = 'Custom Range';
          }
        } else if (filters[i][1].length && filters[i][1] != 'all' && val[filters[i][0]].toLowerCase() != filters[i][1]) {

          //console.log({localVal:val[filters[i][0]],localFilter:filters[i][1]})

          bool = false;
        }
      }

      return bool;

    })
  },
  // UPDATE: need to include changes from filterMundial
  filterCharts(filters) {
    const reformatDate = this.reformatDate, self = this,
    filterLen = filters.length;

    //wconsole.log({filters})
    //console.log({mundial:this.mmData.mundial})

    return this.mmData.mundial_charts.filter(function(val) {


      //console.log({val, filters, length:filters.length})


      let bool = true;
      for(let i = 0; i < filterLen; i++) {


        //console.log({val:val[filters[i][0]],filter:filters[i]})


        if(filters[i][0] == 'date') {
          const objCheck = filters[i][1][0] instanceof Date,
          sDate = objCheck ? parseInt(new Date(filters[i][1][0].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][0].replaceAll('-','')),
          eDate = objCheck ? parseInt(new Date(filters[i][1][1].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][1].replaceAll('-','')),
          tmpDate = reformatDate(val[filters[i][0]]);



          //console.log({sDate,eDate,tmpDate})



          if (tmpDate < sDate - 1) {
            bool = false;
          }
          if (tmpDate > eDate - 1) {
            bool = false;
          }
          if (objCheck) {
            self.mmText = 'Custom Range';
          }
        } else if (filters[i][1].length && filters[i][1] != 'all' && val[filters[i][0]].toLowerCase() != filters[i][1]) {

          //console.log({localVal:val[filters[i][0]],localFilter:filters[i][1]})

          bool = false;
        }
      }

      return bool;

    })
  },
  dateRangeArr(sDate, numOfDays) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24,
    startDate = new Date(sDate), startDateInMs = startDate.getTime()

    //console.log({numOfDays})

    return [...Array(numOfDays).keys()].map(i => new Date(startDateInMs + i * _MS_PER_DAY).toISOString().slice(0,10))
  },
  dateDiffDays(sDate, eDate) {
    // Discard the time and time-zone information.
    const _MS_PER_DAY = 1000 * 60 * 60 * 24,
    utc1 = Date.UTC(sDate.getFullYear(), sDate.getMonth(), sDate.getDate()),
    utc2 = Date.UTC(eDate.getFullYear(), eDate.getMonth(), eDate.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  },
  reformatDate(oldDate) {
    if (oldDate.indexOf('T') === -1) {
      return parseInt([oldDate.split('-')[2],oldDate.split('-')[0],oldDate.split('-')[1]].join(''))
    } else {
      return parseInt([oldDate.split('-')[0],oldDate.split('-')[1],oldDate.split('-')[2].split('T')[0]].join(''))
    }
  },
  mmDate() {
        Date.prototype.GetFirstDayOfWeek = function() {
            return (new Date(this.setDate(this.getDate() - this.getDay() + (this.getDay() == 0 ? -6 : 1) )));
        }
        Date.prototype.GetLastDayOfWeek = function() {
            return (new Date(this.setDate(this.getDate() - this.getDay() + 7)));
        }
        Date.prototype.GetLastSeven = function() {
            const startDay = new Date(),
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 7);
            //endDay.setTime(endDay.getTime() - 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }
        Date.prototype.GetLastThirty = function() {
            const startDay = new Date(),
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 30);
            //endDay.setTime(endDay.getTime() - 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }
        Date.prototype.GetThisWeek = function() {
            const startDay = this.GetFirstDayOfWeek(),
              endDay = this.GetLastDayOfWeek();
            return ({startDay, endDay});
        }
        Date.prototype.GetThisMonth = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth(), 1),
              endDay = new Date(this.getFullYear(), this.getMonth() + 1, 0);
            return ({ startDay, endDay });
        }
        Date.prototype.GetLastWeek = function() {
            const startDay = this.GetFirstDayOfWeek(),
              endDay = this.GetLastDayOfWeek();
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 6);
            endDay.setTime(endDay.getTime() - 3600 * 1000 * 24 * 6);
            return ({startDay, endDay});
        }
        Date.prototype.GetLastMonth = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth() - 1, 1),
              endDay = new Date(this.getFullYear(), this.getMonth(), 0);
            startDay.setTime(startDay.getTime() + 3600 * 1000 * 24);
            endDay.setTime(endDay.getTime() + 3600 * 1000 * 24);
            return ({startDay, endDay});
        }
        Date.prototype.GetMonthToDate = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth(), 1),
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() + 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }

        const adjDate = new Date();
        adjDate.setTime(adjDate.getTime() - 3600 * 1000 * 8)

        return adjDate
  },
  clearMundialTimeout() {
        clearTimeout(this.session_timeout)
  },
  mmTimeout() {
        const minutes = 90;
        //const minutes = 0.5;
        this.session_timeout = setTimeout(() => { this.confirmSession() }, 1000 * 60 * minutes)
  },
  async logoutMundial() {
        const LogOut = await axios.get('logout/').catch(function (error) { return {data: {logout: 'undefined'}} }),
        boolCheck = typeof LogOut.data.logout !== "undefined" ? LogOut.data.logout : 'expired';

        //console.log({boolCheck, store:this, session_router:this.session_router})

        // MUST match [[globals]]
        if (boolCheck) {
            this.propsToSearch = {}
            this.tableColumns = {}
            this.colWidthDict = false
            this.ogPropsToSearch = false
            this.ogTableColumns = false
            this.userConfirm = false
            this.mmData = false
            this.reset_pass = false
            this.customQuery = false
            this.mmLoader = false
            this.mmCustomCSVHeaders = {}
            this.aggregateSelected = 'total'
            this.companySelected = 'all'
            this.siteSelected = ['all']
            this.productSelected = ['all']
            this.subProductSelected = ['all']
            this.productSubtypeSelected = ''
            this.segmentSelected = ''
            this.demoSelected = ''
            this.reachSelected = ''
            this.campaignSelected = ''
            this.placementSelected = ['all']
            this.languageSelected = ''
            this.dmaSelected = ''
            this.genderSelected = ''
            this.ageSelected = ''
            this.deviceSelected = 'all'
            this.interSelected = 'all'
            this.intervalSelected = 15
            this.keywordsInput = ''
            this.csvFile = ''
            this.exportRows = {}
            this.mundialData = []
            this.mmText = 'Last 30 Days'
            this.mmDateRange = []
            this.mmMinMaxDate = []
            this.customMinMaxDate = []
            //this.customMinMaxDate = {}
            this.mmAvailsRange = '7'
        }

        // Redirect Login (expired sessions)
        if(boolCheck === 'expired'){
            this.session_router.push({'name':'Login'})
            this.session_router = false
            this.clearMundialTimeout()
        }
  },
  async confirmTC(tcForm) {
        // POST Confirm Terms of Use
        const UpdateData = await axios.post('confirmterms/',tcForm),
        boolCheck = UpdateData.data.user;

        if (boolCheck) {
          this.mmData.user = boolCheck
        }
  },
  async valid_reset_pass() {
        // POST Confirm Terms of Use
        const email = this.reset_pass ? this.reset_pass.email : false,
        password = this.reset_pass ? this.reset_pass.password : false,
        ValidResetPass = email && password ? await axios.post('validreset/',{ email, password }) : false,
        boolCheck = ValidResetPass ? ValidResetPass.data.valid_reset : ValidResetPass;

        //console.log({ValidResetPass, boolCheck})

        return boolCheck
  },
  async resetSessionFilters(sessionForm) {
        // POST add filters to Django session
        const UserSession = await axios.post('sessionfilters/',sessionForm), InvalidSess = UserSession.data.invalid_session;

        // Alert session errors
        if (InvalidSess) {
          alert('Session invalid! Please notify Mundial Media admins.')
        }
  },
  async resetMundialPass(resetPassForm) {
        //console.log('_____')
	//console.log('')
        //console.log({resetPassForm})
	//console.log('')
        //console.log('_____')

        // POST InvalidPass
        const ValidatePass = await axios.post('resetpass/',resetPassForm), InvalidPass = ValidatePass.data.invalid_pass;
        this.mmData = InvalidPass

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (InvalidPass) {
          alert(`Please check your email inbox for ${resetPassForm.email}!`)
        }

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
  },
  async setNewPass(setPassForm) {
        //console.log('_____')
	//console.log('')
        //console.log({setPassForm})
	//console.log('')
        //console.log('_____')

        // POST InvalidPass
        const ValidatePass = await axios.post('setnewpass/',setPassForm), InvalidPass = ValidatePass.data.invalid_pass;
        this.mmData = InvalidPass

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (!InvalidPass) {
          this.reset_pass = false
          alert('Password set successfully!')
        }

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
  },
  async confirmSession() {
        // GET Confirm Session
        const SessionData = await axios.get('confirmsession/').catch(function (error) { return {data: {status: 'undefined'}} }),
        boolCheck = typeof SessionData.data.status !== 'undefined' ? SessionData.data.status : false;

        //console.log({boolCheck})

        // Logout Mundial (if expired)
        if (!boolCheck) {
          this.logoutMundial()
        } else {
          this.clearMundialTimeout()
          this.mmTimeout()
        }
  },
  async loginMundial(loginForm) {
        if (loginForm.password) this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const UserData = await axios.post('login/',loginForm)

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        this.mmData = UserData.data.user ? UserData.data : false
        if (this.mmData) this.mmTimeout()

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.mmLoader = false
  },
  async initMundial() {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({store:this})
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const MundialData = await axios.get('initdata/'),
        mundialCheck = MundialData.data.mundial,
        preIrisCheck = MundialData.data.pre_iris;
        //statusCodesCheck = MundialData.data.status_codes;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')

        if (mundialCheck) this.mmData.mundial = mundialCheck
        if (preIrisCheck) this.mmData.pre_iris = preIrisCheck
        //if (statusCodesCheck) this.mmData.status_codes = statusCodesCheck

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.mmLoader = false
  },
  async initStatusCodes() {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({store:this})
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const MundialData = await axios.get('initstatuscodes/'),
        mundialCheck = MundialData.data.status_codes;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')

        if (mundialCheck) this.mmData.status_codes = mundialCheck

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.mmLoader = false
  },
  async createMundialMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('createmundialmap/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            this.mmData.ignored_list = boolCheck.ignored_list
            this.mmData.unmapped_list = boolCheck.unmapped_list
        }

        this.mmLoader = false
  },
  async createMundialCostMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('createmundialcostmap/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            this.mmData.costmap_list = boolCheck.costmap_list
            this.mmData.unmapped_list = boolCheck.unmapped_list
        }

        this.mmLoader = false
  },
  async createMundialSnCostMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('createmundialsncostmap/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            this.mmData.sncostmap_list = boolCheck.sncostmap_list
            this.mmData.ignored_list = boolCheck.ignored_list
        }

        this.mmLoader = false
  },
  async createNewMundialSnCostMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('newmundialsncostmap/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            this.mmData.sncostmap_list = boolCheck.sncostmap_list
            this.mmData.ignored_list = boolCheck.ignored_list
        }

        this.mmLoader = false
  },
  async createNewMundialPlacementGroup(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('newmundialplacementgroup/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            this.mmData.unmapped_placement_options = boolCheck.unmapped_placement_options
            this.mmData.unmapped_placement_list = boolCheck.unmapped_placement_list
            this.mmData.mapped_campaign_list = boolCheck.mapped_campaign_list
        }

        this.mmLoader = false
  },
  async createNewMundialPlacementGroupMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('newmundialplacementgroupmap/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            this.mmData.unmapped_placement_options = boolCheck.unmapped_placement_options
            this.mmData.unmapped_placement_list = boolCheck.unmapped_placement_list
        }

        this.mmLoader = false
  },
  async createMundialCampaign(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('createmundialcampaign/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck === 'EXISTING_UNIQUE') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.campaign_options = boolCheck.campaign_options
            this.mmData.campaign_list = boolCheck.campaign_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async createMundialPlacement(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('createmundialplacement/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck === 'EXISTING_UNIQUE') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.placement_options = boolCheck.placement_options
            this.mmData.placement_list = boolCheck.placement_list
            this.mmData.unmapped_placement_options = boolCheck.unmapped_placement_options
            this.mmData.unmapped_placement_list = boolCheck.unmapped_placement_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async createMundialBE(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('createmundialbe/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck === 'EXISTING_BE') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.be_options = boolCheck.be_options
            this.mmData.be_list = boolCheck.be_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async createMundialIgnored(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('createmundialignored/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck === 'EXISTING_IGNORED') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.ignored_list = boolCheck.ignored_list
            this.mmData.unmapped_list = boolCheck.unmapped_list
            this.mmData.sncostmap_list = boolCheck.sncostmap_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async createMundialCleanSiteMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('createmundialcleansitemap/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck === 'EXISTING_CLEAN') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.cname_list = boolCheck.cname_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async deleteMundialCleanSiteMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deletemundialcleansitemap/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.cname_list = boolCheck.cname_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async deleteMundialIgnored(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deletemundialignored/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.ignored_list = boolCheck.ignored_list
            this.mmData.unmapped_list = boolCheck.unmapped_list
            this.mmData.sncostmap_list = boolCheck.sncostmap_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async ignoreMundialCampaign(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('ignoremundialcampaign/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.campaign_options = boolCheck.campaign_options
            this.mmData.campaign_list = boolCheck.campaign_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async deleteMundialCampaign(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deletemundialcampaign/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.campaign_options = boolCheck.campaign_options
            this.mmData.campaign_list = boolCheck.campaign_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async deleteMundialPlacement(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deletemundialplacement/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.placement_options = boolCheck.placement_options
            this.mmData.placement_list = boolCheck.placement_list
            this.mmData.unmapped_placement_options = boolCheck.unmapped_placement_options
            this.mmData.unmapped_placement_list = boolCheck.unmapped_placement_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async deleteMundialBE(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deletemundialbe/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.be_options = boolCheck.be_options
            this.mmData.be_list = boolCheck.be_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async deleteMundialSNExt(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deletemundialsiteext/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.site_ext_options = boolCheck.site_ext_options
            this.mmData.site_ext_list = boolCheck.site_ext_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async deleteMundialSN(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deletemundialsn/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.site_options = boolCheck.site_options
            this.mmData.site_list = boolCheck.site_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async createMundialSN(mForm) {
        this.mmLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('createmundialsn/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck === 'EXISTING_SN') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.site_options = boolCheck.site_options
            this.mmData.site_list = boolCheck.site_list
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async updateMundial(uForm) {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('mundialupdate/',uForm),
        boolCheck = UpdateData.data.user;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.user = boolCheck
            this.mmData.users_list = UpdateData.data.users_list

            return true
        } else {
            return false
        }
  },
  async updateMundialCleanSiteMap(uForm) {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('mundialupdatecleansitemap/',uForm),
        boolCheck = UpdateData.data.mundial;

        // Update State
        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        if (boolCheck === 'EXISTING_CLEAN') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.cname_list = boolCheck.cname_list
            return true
        } else {
            return false
        }
  },
  async getMundialSegmentInsight(mForm) {
        this.mmLoader = true
        //console.log('_____')
        //console.log('')
        //console.log({mForm})
        //console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('getmundialsegmentinsights/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State
        console.log('_ _ _ _ _ _')
        console.log({MundialData})
        console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.segment_insights_list = boolCheck.segment_insights_list
            this.mmData.iab_breakout_dict = boolCheck.iab_breakout_dict

            return true
        } else {
            return false
        }
  },
  async getMundialSnCostMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
        //console.log('')
        //console.log({mForm})
        //console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('getmundialsncostmap/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State
        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.manage_sncostmap_list = boolCheck.manage_sncostmap_list
            this.mmData.remove_sncostmap_list = boolCheck.remove_sncostmap_list
            this.mmData.daterange_flag = boolCheck.daterange_flag

            return true
        } else {
            return false
        }
  },
  async getMundialCampaignPlacementGroupMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
        //console.log('')
        //console.log({mForm})
        //console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('getmundialcampaignplacementgroups/',mForm),
        boolCheck = MundialData.data.mundial;

        // Update State
        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.campaign_placement_group_options = boolCheck.campaign_placement_group_options

            return true
        } else {
            return false
        }
  },
  async updateMundialSnCostMap(mForm) {
        this.mmLoader = true
        //console.log('_____')
        //console.log('')
        //console.log({mForm})
        //console.log('')
        //console.log('_____')

        // POST ProfileData
        //const MundialData = await axios.post('updatemundialsncostmap/',mForm),
        //boolCheck = MundialData.data.mundial;

        // Update State
        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        //if (boolCheck) {
            //this.mmData.sncostmap_list = boolCheck.sncostmap_list
            //this.mmData.unmapped_list = boolCheck.unmapped_list
        //}

        this.mmLoader = false
  },
  async updateMundialIgnored(uForm) {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('mundialupdateignored/',uForm),
        boolCheck = UpdateData.data.mundial;

        // Update State
        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        if (boolCheck === 'EXISTING_IGNORED') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.ignored_list = boolCheck.ignored_list
            this.mmData.unmapped_list = boolCheck.unmapped_list
            this.mmData.sncostmap_list = boolCheck.sncostmap_list
            return true
        } else {
            return false
        }
  },
  async updateMundialCampaign(uForm) {
        //console.log('_____')
        //console.log('')
        //console.log({uForm})
        //console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('mundialupdatecampaign/',uForm),
        boolCheck = UpdateData.data.mundial;

        // Update State
        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        if (boolCheck === 'EXISTING_UNIQUE') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.campaign_options = boolCheck.campaign_options
            this.mmData.campaign_list = boolCheck.campaign_list
            return true
        } else {
            return false
        }
  },
  async updateMundialPlacement(uForm) {
        //console.log('_____')
        //console.log('')
        //console.log({uForm})
        //console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('mundialupdateplacement/',uForm),
        boolCheck = UpdateData.data.mundial;

        // Update State
        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        if (boolCheck === 'EXISTING_UNIQUE') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.placement_options = boolCheck.placement_options
            this.mmData.placement_list = boolCheck.placement_list
            this.mmData.unmapped_placement_options = boolCheck.unmapped_placement_options
            this.mmData.unmapped_placement_list = boolCheck.unmapped_placement_list
            return true
        } else {
            return false
        }
  },
  async updateMundialBE(uForm) {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('mundialupdatebe/',uForm),
        boolCheck = UpdateData.data.mundial;

        // Update State
        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        if (boolCheck === 'EXISTING_BE') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.be_options = boolCheck.be_options
            this.mmData.be_list = boolCheck.be_list
            return true
        } else {
            return false
        }
  },
  async updateMundialSN(uForm) {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('mundialupdatesn/',uForm),
        boolCheck = UpdateData.data.mundial;

        // Update State
        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        if (boolCheck === 'EXISTING_SN') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.site_options = boolCheck.site_options
            this.mmData.site_list = boolCheck.site_list
            return true
        } else {
            return false
        }
  },
  async mmAvails(aForm) {
        //console.log('_____')
	//console.log('')
        //console.log({aForm})
	//console.log('')
        //console.log('_____')

        // POST AvailsData
        const AvailsData = await axios.post('mundialavails/',aForm),
        boolCheck = AvailsData.data.avails;

        // Return avails
        //console.log('_ _ _ _ _ _')
        //console.log({AvailsData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        return boolCheck
  },
  async customMundial() {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const sdate = this.customMinMaxDate[dashName][0].toISOString().split('T')[0], edate = this.customMinMaxDate[dashName][1].toISOString().split('T')[0],
        date_range = [sdate, edate],
        customData = await axios.post('customdata/',{ date_range }),
        boolCheck = customData.data.custom;

        // Return Data

        //console.log('_ _ _ _ _ _')
        //console.log({customData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            return boolCheck
        } else {
            return this.mmData.mundial
        }
  },
  async customStatusCodes() {
        //console.log('_____')
	//console.log('')
        //console.log({dateRange:this.customMinMaxDate})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const sdate = this.customMinMaxDate[0].toISOString().split('T')[0], edate = this.customMinMaxDate[1].toISOString().split('T')[0],
        date_range = [sdate, edate],
        customData = await axios.post('customstatuscodes/',{ date_range }),
        boolCheck = customData.data.custom;

        // Return Data

        //console.log('_ _ _ _ _ _')
        //console.log({customData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            return boolCheck
        } else {
            return this.mmData.statusCodes
        }
  },
  async createMundial(mForm) {
        this.mmLoader = true
        //console.log('_____')
        //console.log('')
        //console.log({mForm})
        //console.log('')
        //console.log('_____')

        // POST ProfileData
        const ProfileData = await axios.post('createmundial/',mForm),
        boolCheck = ProfileData.data.users_list;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({ProfileData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.users_list = boolCheck
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async updatePlatformUser(uForm) {
        //console.log('_____')
        //console.log('')
        //console.log({uForm})
        //console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('updateuser/',uForm),
        boolCheck = UpdateData.data.users_list;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.mmData.users_list = boolCheck
            return true
        } else {
            return false
        }
  },
  async deletePlatformUser(mForm) {
        this.mmLoader = true
        //console.log('_____')
        //console.log('')
        //console.log({mForm})
        //console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deleteuser/',mForm),
        boolCheck = MundialData.data.users_list;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.mmLoader = false
        if (boolCheck) {
            this.mmData.users_list = boolCheck
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async sessionLogin(session_router) {
        await this.loginMundial({email:'sessionid', password:false})

        //console.log({store, session:true})

        // Redirect
        if(store.mmData){
          session_router.push({'name':'Overview'})
        }
  },
  download(data, fileType) {
    // Determine Blob type
    const type = fileType === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' : 'text/csv';
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type });
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // Passing the blob downloading url
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    const ahoraMismo = new Date();
    a.setAttribute('download', `mundial_report_${this.mmText.toLowerCase().replace(' ','_')}__${ahoraMismo.toISOString()}.${fileType}`);
    // Performing a download with click
    a.click()
  },
  csvmaker(exportArr, DASH) {
    console.log({exportArr})

    // Empty array for storing the values
    let csvRows = [], headers = Object.keys(exportArr[0]),
    dataArr = JSON.parse(JSON.stringify(exportArr)),
    dataLen = dataArr.length;
    // Headers is basically a keys of an
    // object which is id, name, and
    // profession
    const adminRemoveArr = this.adminRemoveArr, adminRemoveSet = new Set(adminRemoveArr),
    clientRemoveArr = this.clientRemoveArr, clientRemoveSet = new Set(clientRemoveArr),
    mmRemoveArr = this.mmRemoveArr, mmRemoveSet = new Set(mmRemoveArr),
    is_staff = this.mmData.user.is_staff,
    is_admin = this.mmData.user.is_superuser;
    if(is_staff && !is_admin) {
       headers = headers.filter(function (item) {
          return !mmRemoveSet.has(item);
       });
    } else if (!is_admin) {
       headers = headers.filter(function (item) {
          return !clientRemoveSet.has(item);
       });
    } else {
       headers = headers.filter(function (item) {
          return !adminRemoveSet.has(item);
       });
    }
    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    const cleanHeaders = [],
    colsDict = this.colsDict,
    headLen = headers.length;
    for (let h = 0; h < headLen; h++) {
      const cleanHead = colsDict[headers[h]];
      cleanHeaders.push(cleanHead)
    }
    csvRows.push(cleanHeaders.join(','));
    // Pushing Object values into array
    // with comma separation
    for(let d = 0; d < dataLen; d++) {
       let dataRow = dataArr[d];
       if(is_staff && !is_admin) {
          mmRemoveArr.forEach(e => delete dataRow[e])
       } else if (!is_admin) {
          clientRemoveArr.forEach(e => delete dataRow[e])
       } else {
          adminRemoveArr.forEach(e => delete dataRow[e])
       }

       // TEST (maintained for testing purposes)
       //console.log({dataRow})

       // Add quotes to preserve commas during CSV export
       for(let h = 0; h < headLen; h++) {
         const tmpHeader = headers[h];
         dataRow[tmpHeader] = `\"${dataRow[tmpHeader]}\"`
       }

       //console.log({dataRow})

       const val = Object.values(dataRow).join(',');
       csvRows.push(val)
    }

    // Check for custom headers
    if (this.mmCustomCSVHeaders[DASH]) {
      csvRows.unshift(Array(headLen));


      const mmCustomCSVHeaders = this.mmCustomCSVHeaders[DASH],
      customHeaderKeys = Object.keys(mmCustomCSVHeaders).sort();
      let customHeaderLen = customHeaderKeys.length;
      for (let i = customHeaderLen; i--; ) {
        const tmpKey = customHeaderKeys[i],
        tmpVal = mmCustomCSVHeaders[tmpKey];

        let tmpArr = Array(headLen);
        tmpArr[0] = tmpKey
        tmpArr[1] = tmpVal

        csvRows.unshift(tmpArr);
      }
    }

    // Returning the array joining with new line
    return csvRows.join('\n')
  },
  queriedData(searchQuery, DASH) {
        console.log({searchQuery, propsToSearch:this.propsToSearch, DASH})

        if (!searchQuery.length) {
          //return this.mmData.mundial
          //return this.mundialData
          return this.exportRows[DASH]
        }
        const propsToSearch = this.propsToSearch[DASH],
        lowerSearchQuery = searchQuery.toLowerCase(),
        propLen = propsToSearch.length;
        //let result = this.mmData.mundial
        //let result = this.mundialData
        let result = this.exportRows[DASH]
          .filter((row) => {
            let isIncluded = false


            // TEMP
            //console.log({row})



            for(let k = 0; k < propLen; k++) {
              const tmpKey = propsToSearch[k];

              // TEST (maintained for testing purposes)
              //console.log({key})
              //console.log(row[key])
              //console.log(row[key].toLowerCase())

              let rowValue = row[tmpKey].toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        return result
  },
  async exportMundial(searchQuery, DASH) {
        this.mmLoader = true

        const csvData = this.queriedData(searchQuery, DASH), csvFile = this.csvmaker(csvData, DASH);
        await this.download(csvFile, 'csv');

        this.mmLoader = false
  },
  async getMundialTemplate() {
    const responseType = 'arraybuffer',
    MundialData = await axios.get('exportmundialtemplate/', { responseType }),
    boolCheck = MundialData.data;
    //boolCheck = MundialData.request;

    //console.log({MundialData, boolCheck, blob:MundialData.blob()})
    console.log({MundialData, boolCheck})

    return boolCheck
  },
  async exportMundialTemplate() {
    const template = await this.getMundialTemplate();

    const workbook = await new ExcelJS.Workbook().xlsx.load(template);

    console.log({template, workbook})

    workbook.creator = 'Javier Avitia';
    workbook.lastModifiedBy = 'Javier Avitia';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const worksheet = workbook.addWorksheet("Publications");
    worksheet.views = [
      {state: 'frozen', xSplit: 0, ySplit: 1}
    ];

    worksheet.autoFilter = {
      from: 'A1',
      to: 'M1'
    };

    worksheet.columns = [
      { header: 'Index', key: 'Index', width: 15 },
      { header: 'Title', key: 'title', width: 25, style: {alignment: {wrapText: true} } },
      { header: 'Authors', key: 'authors', width: 20, style: {alignment: {wrapText: true} } },
      { header: 'Journal/Conference', key: 'jc', width: 25, style: {alignment: {wrapText: true} } },
      { header: 'Type', key: 'type', width: 12, style: {alignment: {wrapText: true} } },
      { header: 'Year', key: 'year', width: 12, style: {numFmt: "0000"}},
      { header: 'Month', key: 'month', width: 12},
      { header: 'volume', key: 'volume', width: 12},
      { header: 'number', key: 'number', width: 12},
      { header: 'Pages', key: 'pages', width: 12},
      { header: 'Location', key: 'location', width: 20, style: {alignment: {wrapText: true} } },
      { header: 'doi', key: 'doi', width: 22, style: {alignment: {wrapText: true} } },
      { header: 'affiliation', key: 'affiliation', width: 20, style: {alignment: {wrapText: true} } }
    ];

    const firstRow = worksheet.getRow(1);
    firstRow.font = { name: 'New Times Roman', family: 4, size: 10, bold: true, color: {argb:'80EF1C1C'} };
    firstRow.alignment = { vertical: 'middle', horizontal: 'center'};
    firstRow.height = 20;

    //
    // TESTING
    //
    let csv = [['a','b','c'],['X','Y','Z'],[1,2,3],[7,8,9]]
    //csv.shift();
    worksheet.addRows(csv);

    const buff = await workbook.xlsx.writeBuffer();
    this.download(buff, 'xlsx')
  },
  async exportMundialExcel() {

    const workbook = new ExcelJS.Workbook();

    workbook.creator = 'Javier Avitia';
    workbook.lastModifiedBy = 'Javier Avitia';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const worksheet = workbook.addWorksheet("Publications");
    worksheet.views = [
      {state: 'frozen', xSplit: 0, ySplit: 1}
    ];

    worksheet.autoFilter = {
      from: 'A1',
      to: 'M1'
    };

    worksheet.columns = [
      { header: 'Index', key: 'Index', width: 15 },
      { header: 'Title', key: 'title', width: 25, style: {alignment: {wrapText: true} } },
      { header: 'Authors', key: 'authors', width: 20, style: {alignment: {wrapText: true} } },
      { header: 'Journal/Conference', key: 'jc', width: 25, style: {alignment: {wrapText: true} } },
      { header: 'Type', key: 'type', width: 12, style: {alignment: {wrapText: true} } },
      { header: 'Year', key: 'year', width: 12, style: {numFmt: "0000"}},
      { header: 'Month', key: 'month', width: 12},
      { header: 'volume', key: 'volume', width: 12},
      { header: 'number', key: 'number', width: 12},
      { header: 'Pages', key: 'pages', width: 12},
      { header: 'Location', key: 'location', width: 20, style: {alignment: {wrapText: true} } },
      { header: 'doi', key: 'doi', width: 22, style: {alignment: {wrapText: true} } },
      { header: 'affiliation', key: 'affiliation', width: 20, style: {alignment: {wrapText: true} } }
    ];

    const firstRow = worksheet.getRow(1);
    firstRow.font = { name: 'New Times Roman', family: 4, size: 10, bold: true, color: {argb:'80EF1C1C'} };
    firstRow.alignment = { vertical: 'middle', horizontal: 'center'};
    firstRow.height = 20;

    //
    // TESTING
    //
    let csv = [['a','b','c'],['X','Y','Z'],[1,2,3],[7,8,9]]
    //csv.shift();
    worksheet.addRows(csv);

    const buff = await workbook.xlsx.writeBuffer();
    this.download(buff, 'xlsx')
  },
})
